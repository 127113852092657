<template>
  <div>
    <Head title="Reports" />
    <h1 class="mb-8 text-3xl font-bold">
      Reports
    </h1>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';

export default {
  components: {
    Head,
  },
  layout: Layout,
};
</script>
